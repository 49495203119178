export const API_URL = `${import.meta.env.VITE_API_URL}system/`;
export const MEDIA_URL = import.meta.env.VITE_MEDIA_URL;
export const FULLCALENDAR_LICENSE_KEY = import.meta.env
  .VITE_FULLCALENDAR_LICENSE_KEY;

export enum QUERY_KEYS {
  LOCALIZED_DATA = 'LOCALIZED_DATA',
  SIGN_IN = 'SIGN_IN',
  CHANNELS = 'CHANNELS',
  MEDIA_ITEMS = 'MEDIA_ITEMS',
  PLAYLISTS = 'PLAYLISTS',
  INSERTIONS = 'INSERTIONS'
}
