import { useTranscodeMedia, useGenerateThumbnail } from '@/api/useMedia';
import { MediaItem } from '@/interfaces';
import { useUpsertPlaylist } from './playlist-helper';
import { PlaylistMode } from './types';
import { AuthContext, DataContext } from '@/contexts';
import { useContext } from 'react';
import { MEDIA_URL, QUERY_KEYS } from '@/constants/constants';
import { useQueryClient } from 'react-query';

export const usePostUpload = () => {
  const { user } = useContext(AuthContext);
  const { transcodeMedia } = useTranscodeMedia();
  const { generateThumbnail } = useGenerateThumbnail();
  const { createPlaylist, upsertPlaylist } = useUpsertPlaylist();
  const { setLastUpdatedMedia } = useContext(DataContext);
  const queryClient = useQueryClient();

  const getClientMediaUrl = (filename: string) =>
    MEDIA_URL + user?.clientname + '/' + filename;

  const activateMedia = async (mediaItem: MediaItem) => {
    const transcodeResult = await transcodeMedia(mediaItem);
    const previewUrl = transcodeResult.output_url;
    return await generateThumbnail(mediaItem, previewUrl, 0);
  };

  const postUploadActions = async ({
    filename,
    playlistMode,
    playlistColor,
    onLoad
  }: {
    filename: string;
    playlistMode: PlaylistMode;
    playlistColor: string;
    onLoad: (filename: string) => void;
  }) => {
    const onComplete = (filename: string) => {
      // the load method accepts either a string (id) or an object
      onLoad(filename);

      setLastUpdatedMedia({
        name: filename
      });

      queryClient.invalidateQueries(QUERY_KEYS.MEDIA_ITEMS);
    };

    var mediaItem = {
      name: filename,
      source: getClientMediaUrl(filename)
    } as MediaItem;

    if (playlistMode === 'multiple') {
      // Generate a playlist for each media item
      const thumbnail = await activateMedia(mediaItem);

      await createPlaylist(
        filename,
        thumbnail.id,
        mediaItem,
        playlistColor,
        () => onComplete(filename)
      );
    } else if (playlistMode === 'one') {
      // Generate a single playlist for all media items
      const thumbnail = await activateMedia(mediaItem);

      await upsertPlaylist(
        filename,
        thumbnail.id,
        mediaItem,
        playlistColor,
        () => onComplete(filename)
      );
    } else {
      // No playlist generation
      await activateMedia(mediaItem);
      onComplete(filename);
    }
  };

  return { postUploadActions };
};
