import { API_URL } from '@/constants/constants';
import { AuthContext } from '@/contexts';
import { ProgressServerConfigFunction } from 'filepond';
import { useContext } from 'react';

type ActualFileObject = Blob & {
  readonly lastModified: number;
  readonly name: string;
};

type UploadToServer = {
  fieldName: string;
  file: ActualFileObject;
  onProgress: ProgressServerConfigFunction;
  onAbort: () => void;
};

export const useUploadToServer = () => {
  const { user } = useContext(AuthContext);

  const uploadToServer = ({
    fieldName,
    file,
    onProgress,
    onAbort
  }: UploadToServer): Promise<{
    filename: string;
  }> => {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      const url = `${API_URL}upload.php?clientname=${user?.clientname}&context=media&un=${user?.username}&pw=${user?.password}&location_id=`;

      request.open('POST', url);
      request.withCredentials = true;

      // Should call the progress method to update the progress to 100% before calling load
      // Setting computable to false switches the loading indicator to infinite mode
      request.upload.onprogress = (e) => {
        onProgress(e.lengthComputable, e.loaded, e.total);
      };

      // Should call the load method when done and pass the returned server file id
      // this server file id is then used later on when reverting or restoring a file
      // so your server knows which file to return without exposing that info to the client
      request.onload = function () {
        let json: any = {};
        try {
          json = JSON.parse(request.responseText);
        } catch (error) {
          console.error('Filepond', error);
        }

        const success =
          request.status >= 200 && request.status < 300 && !json.error;

        if (success) {
          resolve(json.result);
        } else {
          reject(
            json.error
              ? json.error.message + ' (' + json.error.code + ')'
              : `Upload failed with status ${request.status}`
          );
        }
      };

      request.onerror = () => {
        reject(new Error('Network error occurred during upload'));
      };

      // Handle timeout
      request.timeout = 30000; // 30 seconds
      request.ontimeout = () => {
        reject(new Error('Upload timed out'));
      };

      try {
        // fieldName is the name of the input field
        // file is the actual file object to send
        const formData = new FormData();
        formData.append(fieldName, file, file.name);
        formData.append('name', file.name);

        request.send(formData);
      } catch (e) {
        reject(e);
      }

      // Should expose an abort method so the request can be cancelled
      return {
        abort: () => {
          // This function is entered if the user has tapped the cancel button
          request.abort();

          // Let FilePond know the request has been cancelled
          onAbort();
        }
      };
    });
  };

  return { uploadToServer };
};
