import { useSavePlaylist } from '@/api/usePlaylist';
import { MediaItem, PlaylistFormValues } from '@/interfaces';
import { useState } from 'react';

export const useUpsertPlaylist = () => {
  const { savePlaylist } = useSavePlaylist();
  const [playlistGenerated, setPlaylistGenerated] = useState<{
    id: number | null;
    name: string;
    medias: MediaItem[];
  }>();

  const createPlaylist = async (
    filename: string,
    thumbnailId: number,
    mediaItem: MediaItem,
    playlistColor: string,
    onSave: () => void
  ) => {
    const newMedia = {
      ...mediaItem,
      id: thumbnailId
    };

    const playlist: PlaylistFormValues = {
      id: null,
      color: playlistColor.substring(1),
      name: filename,
      comment: '',
      content: [],
      medias: [newMedia]
    };

    await savePlaylist(playlist);

    onSave();
  };

  const upsertPlaylist = async (
    filename: string,
    thumbnailId: number,
    mediaItem: MediaItem,
    playlistColor: string,
    onSave: () => void
  ) => {
    const newMedia = {
      ...mediaItem,
      id: thumbnailId
    };

    const newMedias = [...(playlistGenerated?.medias || []), newMedia];

    const playlist: PlaylistFormValues = {
      id: playlistGenerated?.id || null,
      color: playlistColor.substring(1),
      name: playlistGenerated?.name || filename,
      comment: '',
      content: [],
      medias: newMedias
    };

    const playlistResult = await savePlaylist(playlist);

    onSave();

    setPlaylistGenerated({
      id: playlistResult.id,
      name: playlist.name,
      medias: newMedias
    });
  };

  return { createPlaylist, upsertPlaylist };
};
