import { randomColor } from '@/lib/random-color';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from '@material-ui/core';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import React, { useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import { usePostUpload } from './post-upload-helper';
import { useUploadToServer } from './upload-helper';
import { PlaylistMode } from './types';

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const MediaUploadDialog: React.FC = () => {
  const { postUploadActions } = usePostUpload();
  const { uploadToServer } = useUploadToServer();
  const [playlistMode, setPlaylistMode] = useState<PlaylistMode>('multiple');
  const [playlistColor, setPlaylistColor] = useState<string>(randomColor());
  const [isUploading, setIsUploading] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState(false);
  const controlsDisabled = isUploading || filesUploaded;
  const colorDisabled =
    isUploading || (filesUploaded && playlistMode === 'one');

  // If playlistMode is 'one' we need to upload sequentially
  // since the playlist is generated in the first upload
  // and we need the playlist id for the next upload
  const maxParallelUploads = playlistMode === 'one' ? 1 : 2;

  const handleNewColor = () => {
    setPlaylistColor(randomColor());
  };

  return (
    <Box margin={2}>
      <Box marginBottom={2}>
        <Typography variant="h3" gutterBottom>
          Upload
        </Typography>
        <Typography variant="body2">
          Du kan uploade en eller flere billed- eller videofiler ad gangen.
        </Typography>
      </Box>

      <Box width="100%" mx={1} mb={3}>
        <FormControl>
          <RadioGroup>
            <FormControlLabel
              control={<Radio size="small" disabled={controlsDisabled} />}
              label={
                <>
                  Opret en playliste til hver enkelt af de uploadede filer{' '}
                  <Tooltip title="Skift farve" placement="right">
                    <Button
                      onClick={handleNewColor}
                      variant="outlined"
                      disableRipple
                      disabled={colorDisabled}
                      style={{
                        padding: 4,
                        minWidth: 10
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: playlistColor,
                          borderRadius: 4,
                          display: 'inline-block',
                          height: 11,
                          width: 11
                        }}
                      ></span>
                    </Button>
                  </Tooltip>
                </>
              }
              onChange={(_, value: boolean) => setPlaylistMode('multiple')}
              checked={playlistMode === 'multiple'}
            />
            <FormControlLabel
              control={<Radio size="small" disabled={controlsDisabled} />}
              label={
                <>
                  Opret én enkelt playliste med alle uploade filer{' '}
                  <Tooltip title="Skift farve" placement="right">
                    <Button
                      onClick={handleNewColor}
                      variant="outlined"
                      disableRipple
                      disabled={colorDisabled}
                      style={{
                        padding: 4,
                        minWidth: 10
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: playlistColor,
                          borderRadius: 4,
                          display: 'inline-block',
                          height: 11,
                          width: 11
                        }}
                      ></span>
                    </Button>
                  </Tooltip>
                </>
              }
              onChange={(_, value: boolean) => setPlaylistMode('one')}
              checked={playlistMode === 'one'}
            />
            <FormControlLabel
              control={<Radio size="small" disabled={controlsDisabled} />}
              label="Upload kun filer."
              onChange={(_, value: boolean) => setPlaylistMode('none')}
              checked={playlistMode === 'none'}
            />
          </RadioGroup>
        </FormControl>
      </Box>

      <FilePond
        allowMultiple={true}
        allowRevert={false}
        allowRemove={false}
        allowFileSizeValidation
        allowFileTypeValidation
        acceptedFileTypes={['image/png', 'image/jpg', 'image/jpeg', 'video/*']}
        maxFiles={30}
        maxFileSize="200mb"
        maxParallelUploads={maxParallelUploads}
        name="file"
        onaddfilestart={() => setIsUploading(true)}
        onprocessfiles={() => {
          setIsUploading(false);
          setFilesUploaded(true);
        }}
        server={{
          process: function (
            fieldName,
            file,
            _metadata,
            onLoad,
            onError,
            onProgress,
            onAbort
          ) {
            uploadToServer({
              fieldName,
              file,
              onProgress,
              onAbort
            })
              .then(async ({ filename }) => {
                // Call the postUpload method to handle the rest
                await postUploadActions({
                  filename,
                  playlistMode,
                  playlistColor,
                  onLoad
                });
              })
              .catch((err) => {
                // Can call the error method if something is wrong
                onError(err);
                console.error('Upload error:', err);
              });
          }
        }}
      />
    </Box>
  );
};

export default MediaUploadDialog;
