import { Playlist, PlaylistFormValues } from '../interfaces';
import { xml2json } from '../lib/xml-parser';
import { useFetcher } from './useFetcher';
import { SnackbarContext } from '../components/Snackbar/Snackbar';
import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { objToFormData } from './formData';
import { QUERY_KEYS } from '../constants/constants';
import { useHistory } from 'react-router-dom';
import { AuthContext, DataContext } from 'contexts';
import { htmlDecode } from 'helpers/htmlHelper';
import { objectToArray } from 'helpers/arrayHelper';
import { querystring2json } from '@/lib/querystring-parser';

export const useGetPlaylists = () => {
  const { user } = useContext(AuthContext);
  const { fetcher } = useFetcher();

  const getPlaylists = async (): Promise<Playlist[]> => {
    return new Promise(async (resolve) => {
      const result = await fetcher(
        `get_playlists.php?is_admin=${user?.is_admin}&uid=${user?.username}&groupname=`
      );
      const json = xml2json(result, 'name');
      let playlists = json?.rootnode?.playlist || [];

      // The xml2json parser will return either an object or an array of objects.
      // Let's make it easy and put a single object into an array.
      playlists = objectToArray(playlists);

      const decoded = playlists.map((playlist: any) => ({
        ...playlist,
        name: htmlDecode(playlist.name)
      }));

      resolve(decoded);
    });
  };
  return { getPlaylists };
};

export const useGetPlaylist = () => {
  const { fetcher } = useFetcher();

  const getPlaylist = async (id: number | string): Promise<Playlist> => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await fetcher(`get_playlist.php?content_id=${id}`);
        if (result) {
          const json = xml2json(result, 'name', {
            arrayMode: (tagName: string) => tagName === 'item'
          });

          const decoded = {
            ...json?.playlist,
            name: htmlDecode(json?.playlist.name)
          };
          resolve(decoded);
        }
      } catch (error: any) {
        reject(error);
      }
    });
  };
  return { getPlaylist };

  // <?xml version="1.0"?>
  // <playlist id="1360" comment="" color="FFF500">
  // <item media_id="1346" id="1346" type="movie" location_id="" category="" movie="http://media.spotproduction.dk/Spotproduction/NS_test_fast_preset.wmv.mp4" source="http://media.spotproduction.dk/Spotproduction/NS_test_fast_preset.wmv" thumb="http://media.spotproduction.dk/Spotproduction/NS_test_fast_preset.wmv.jpg" locked="" orientation="landscape" is_fallback="">NS_test_fast_preset</item>
  // Control counter :)
  // </playlist>
};

export const useSavePlaylist = () => {
  const history = useHistory();
  const { fetcher } = useFetcher();
  const { createSnack } = useContext(SnackbarContext);
  const { currentChannel } = useContext(DataContext);
  const queryClient = useQueryClient();

  const savePlaylist = async (
    values: PlaylistFormValues
  ): Promise<{
    id: number;
  }> => {
    const formData = objToFormData({
      playlist_id: values.id || null,
      playlist_title: values.name,
      playlist_color: values.color,
      playlist_comment: values.comment || '',
      playlist_modified: true,
      playlist_content: values?.medias?.map((x) => x?.id).join(',') || ''
    });
    const result = await fetcher('save_playlist.php', {
      method: 'POST',
      body: formData
    });

    return new Promise((resolve, reject) => {
      const jsonResult = querystring2json(result);

      if (jsonResult.result === 'true') {
        queryClient.invalidateQueries(QUERY_KEYS.PLAYLISTS);
        queryClient.invalidateQueries([
          QUERY_KEYS.INSERTIONS,
          currentChannel?.id
        ]);
        createSnack(
          values.id ? 'Playlisten blev gemt.' : 'Playlisten blev oprettet.',
          {}
        );

        history.push('/');

        resolve({
          id: parseInt(jsonResult.playlist_id)
        });
      } else {
        createSnack('Nah not so much!', { severity: 'error' });
        reject();
      }
    });
  };
  return { savePlaylist };
};

export const useDeletePlaylist = () => {
  const { fetcher } = useFetcher();
  const { createSnack } = useContext(SnackbarContext);
  const queryClient = useQueryClient();

  const deletePlaylist = async (playlistId: number): Promise<void> => {
    const formData = objToFormData({
      playlist_id: playlistId
    });
    const result = await fetcher('remove_playlist.php', {
      method: 'POST',
      body: formData
    });

    return new Promise((resolve, reject) => {
      if (result.startsWith('result=true')) {
        queryClient.invalidateQueries(QUERY_KEYS.PLAYLISTS);
        createSnack('Playlisten blev slettet.', {});
        resolve();
      } else {
        createSnack('Nah not so much!', { severity: 'error' });
        reject();
      }
    });
  };
  return { deletePlaylist };
};
